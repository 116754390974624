import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';

(function($) {

  if ( $('.strapline-swiper').length !== 0 ) {
    // DOM ready, take it away

    /* Swiper show only on mobile */
    var swiper = Swiper;
    var init = false;
    function swiperMode() {
      let mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");

      if (mobile.matches) {
        if (!init) {
          init = true;
          swiper = new Swiper(".strapline-swiper", {
            modules: [ EffectFade, Autoplay ],
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            slidesPerView: 1,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
            },
          });
        }
      } else {
        if ( init ) {
          swiper.destroy();
          init = false;
        }
      }
    }

    window.addEventListener("load", function () {
      swiperMode();
    });

    window.addEventListener("resize", function () {
      swiperMode();
    });

  }

})(jQuery);