import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

(function($) {


  if ( $('.review-slider').length !== 0 ) {
    const wrapper = document.getElementById('review-slider');
    const swiper = new Swiper('.review-slider', {
      modules: [ Pagination ],
      // Optional parameters
      direction: 'horizontal',
      slidesPerView: 'auto',
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });




  }

})(jQuery);
