import Lazy from "blazy";

// Initiate a new bLazy instance for <img/>,
// CSS `background-image` and <iframe/> lazy loading.
// Documentation: http://dinbror.dk/blog/blazy
const lazy = new Lazy({
  selector: ".lazy"
});

//Set as global so we can revalidate in other scripts (e.g. content behind tabs is shown)
window.lazy = lazy; 

// Re-run script when we resize the browser.
// This is a fix for showing previously hidden content after a resize
window.addEventListener("resize", lazy.revalidate);


var video = document.getElementById('video-player');

function initVideo() {
  // Exit the function if no Videos are on page.
  if (!video) return;

  var videoToggle = document.getElementById('video-play');
  videoToggle.addEventListener("click", function(e) {
    video.classList.toggle('hidden');
    videoToggle.classList.toggle('hidden');
    window.lazy && window.lazy.revalidate();
  });
}

initVideo();