// Handler that uses various data-* attributes to trigger
// specific actions, mimicing bootstraps attributes
const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));

for (var i = 0, length = triggers.length; i < length; i++) {
  var anchor = triggers[i];
  anchor.addEventListener('click', function(e) {
    e.preventDefault();
    const selector = this.getAttribute('href');
    collapse(selector, 'toggle', this);
  }, true);
}


const fnmap = {
  'toggle': 'toggle',
  'show': 'add',
  'hide': 'remove'
};

const collapse = (selector, cmd, trigger) => {
  const targets = Array.from(document.querySelectorAll(selector));
  trigger.classList[fnmap[cmd]]('show');
  targets.forEach(target => {
    target.classList[fnmap[cmd]]('show');
  });
}