import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

(function($) {


  if ( $('.hero-swiper').length !== 0 ) {
    const wrapper = document.getElementById('hero-swiper');
    const swiper = new Swiper('.hero-swiper', {
      // Optional parameters
      modules: [ Navigation, Pagination, Autoplay ],
      direction: 'horizontal',
      loop: true,
      slidesPerView: 'auto',
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        horizontalClass: "swiper-pagination-horizontal",
        bulletClass:
          "inline-block h-3 w-3 rounded-full ring-1 ring-neutrals-500 mx-1 cursor-pointer",
        bulletActiveClass: "hero-dot-active",
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        }
      },

      // Navigation arrows
      // navigation: {
      //   nextEl: '.slider-controls-next',
      //   prevEl: '.slider-controls-prev',
      // },
      on: {
        init: function(swiper) {
          var appearance = this.slides[ 0 ].getAttribute('data-appearance');
          this.appearance = appearance;
        },
        autoplayTimeLeft: function(s, time, progress) {
          wrapper.style.setProperty("--progress", 1 - progress);
        },
        realIndexChange: function() {
          var appearance = this.slides[ this.activeIndex ].getAttribute('data-appearance');
          if ( wrapper && appearance && this.appearance ) {
            wrapper.classList.remove(this.appearance);
          }
          wrapper.classList.add(appearance);
          this.appearance = appearance;
        }
      }
    });




  }

})(jQuery);
