import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

(function($) {


  if ( $('.blog-swiper').length !== 0 ) {
    const wrapper = document.getElementById('blog-swiper');
    const swiper = new Swiper('.blog-swiper', {
      modules: [ Pagination ],
      // Optional parameters
      direction: 'horizontal',
      slidesPerView: 'auto',
      centeredSlides: true,
      initialSlide: 1,
      spaceBetween: 64,
      breakpoints: {
        1024: {
          spaceBetween: 128
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });




  }

})(jQuery);
