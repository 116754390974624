import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

(function($) {


  if ( $('.banner-marquee').length !== 0 ) {
    new Swiper( '.banner-marquee',
    {
      modules: [ Autoplay ],
      speed: 5000,
      autoplay: {
        delay: 5,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      loop: true,
      slidesPerView: "auto",
      watchSlidesProgress: true,
      spaceBetween: 0,
      grabCursor: true
    });
  }

})(jQuery);
