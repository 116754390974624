function initProductCollapse() {

  const collapse = document.querySelectorAll('.product-collapse-head')

  if (collapse.length) {
    for (let item of collapse) {
      item.addEventListener('click', (e) => {
          item.parentElement.classList.toggle('open');
          window.lazy && window.lazy.revalidate();
      })
    }
  }

}

initProductCollapse();
