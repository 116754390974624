import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

(function($) {

  if ( $('.press-marquee').length !== 0 ) {
    new Swiper( '.press-marquee',
    {
      modules: [ Autoplay ],
      direction: 'horizontal',
      slidesPerView: 'auto',
      spaceBetween: 0,
      loop: true,
      speed: 5000,
      centeredSlides: true,
      autoplay: {
        delay: 0.1,
        disableOnInteraction: false,
      },
    });
  }

})(jQuery);