import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules'

(function($) {

  if( $('.recommended-products-swiper').length !== 0 ) {
    const progressSwiper = new Swiper('.recommended-products-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 32,
      initialSlide: 1,
      mousewheel: {
        forceToAxis: true,
      },
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
  }

})(jQuery);