let faqs = document.querySelectorAll(".faq");

function initFaqs() {
  // Exit the function if no FAQ's are one page
  if (!faqs) return;

  // Loop through all FAQ's to add the click listener
  for (let faq of faqs) {
    faq.addEventListener("click", () => {
      faq.querySelector(".faq-hidden").classList.toggle("hidden");
      faq.querySelector(".faq-shown").classList.toggle("hidden");
    });
  }
}

initFaqs();
