(function($) {
  var currency = '£'; 
  function updatePrice() { 
    //Get quantity value
    var quantity = $('#grouped_form input[name=quantity]').val();
    //Get price from data attribute
    var price = $('#grouped_form input[name=add-to-cart]:checked').data('price');
    var interval_text = $('#grouped_form input[name=add-to-cart]:checked').data('interval');
    //Calculate cost based on price * quantity
    var overall_total = price * quantity;

    if ( overall_total > 0 ) {
      var string = currency + overall_total.toFixed(2);
      $('#product-total-price .price').html( string + interval_text ); 
    } else {
      $('#product-total-price .price').html( '0' );
    }

  }
  $('#grouped_form input[type=radio][name=add-to-cart]').on('change', function(){
    updatePrice();
  });
  $('#grouped_form input[name=quantity]').on('change', function() {
    updatePrice();
  }); 
  updatePrice();
})(jQuery);