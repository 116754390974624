function initMenuToggle() {
  const header = document.querySelector(".header");
  const nav = document.querySelector(".header__nav");
  const menuToggle = document.querySelector(".menu-burger");
  const accountMenuToggle = document.querySelector(".account-menu");

  if ( accountMenuToggle ) {
      // Add the click listener that toggles active classes
    accountMenuToggle.addEventListener("click", function() {
      accountMenuToggle.classList.toggle("on");
      accountMenuToggle.parentNode.classList.toggle("on");
    });
  }

  // Exit if nav elements not found
  if (!nav || !menuToggle) return;

  // Add the click listener that toggles active classes
  menuToggle.addEventListener("click", function() {
    document.body.classList.toggle("nav-open");
    header.classList.toggle("header--active");
    nav.classList.toggle("header__nav--active");
    menuToggle.classList.toggle("on");
  });

  window.addEventListener("click", function(event) {
    if ( event.target.contains( header ) && document.body.classList.contains( "nav-open" ) ) {
      document.body.classList.remove("nav-open");
      header.classList.remove("header--active");
      nav.classList.remove("header__nav--active");
      menuToggle.classList.remove("on");
    }
  });

  // Remove active classes on window resize
  window.addEventListener("resize", function() {
    document.body.classList.remove("nav-open");
    header.classList.remove("header--active");
    nav.classList.remove("header__nav--active");
    menuToggle.classList.remove("on");
  });
  
}

initMenuToggle();
